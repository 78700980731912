@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;600;700;800&display=swap);
body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

